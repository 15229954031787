"use strict";
window.addEventListener("DOMContentLoaded", function(e){
  // Find all Errorlists
  let errors = document.querySelectorAll(".errorlist");

  errors.forEach((error) => {
    // Mark all invalid fieldrows with a warning class
    let fieldrow = error.parentNode;
    fieldrow.classList.add("warning");

    // Create span-tag for the warning icon
    let iconSpan = document.createElement("span");
    iconSpan.classList.add("warning-icon");

    fieldrow.insertBefore(iconSpan, error);
  });
});
