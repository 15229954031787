
'use strict';

window.addEventListener("DOMContentLoaded", function(e){
    ///// ACCORDION FUNCTIONALITY /////

    let accordionSections = document.querySelectorAll('.accordion-section');


    // Add/Remove animation classes

    let ToggleHidden = (element) => {

        element.classList.toggle('expanded');

    }

    // Add Event-Listener to section titles

    accordionSections.forEach(section => {

        let title = section.querySelector('.accordion-section-title');
        
        title.addEventListener('click', (e) => {

            //ToggleHidden(section);
            section.classList.toggle('expanded');

        });

    });




    ///// CHECKBOX-COUNT FOR METAL ANALYSIS FORM /////

    let checkboxes = document.querySelectorAll('input[name=untersuchungsprogramm]');

    // Count checked Checkboxes

    let CountChecked = () => {
        return document.querySelectorAll('input[type=checkbox]:checked').length; 
    }

    // Calculate total costs

    let MatchPrice = (checkcount) => {

        let total = 0;
        
        // switch (checkcount) {
        //     case 0:
        //         total = 0; // No metals selected
        //         break;
        //     case 1:
        //         total = 22; // Price for one metal
        //         break;
        //     case 2 :
        //         total = 32; // Price for two metals
        //         break;
        //     case 3:
        //         total = 42; // Price for three metals
        //         break;
        //     default:
        //         total = 42 + (checkcount-3)*10; // Any additional metals
        //     }


        if (checkcount) total = 22 + (checkcount-1)*10;
        return total;
        
    }

    // Write total into DOM-Element

    let FillSpan = () => {

        let count = CountChecked(); // Count Checkboxes
        let pricematch = MatchPrice(count); // Match price to selection

        // Write into selection-costs span defined in metallanalytik_form_block.html template

        document.querySelector('.selection-costs').innerHTML = `${count} ${ count === 1 ? 'Metall' : 'Metalle'} — ${pricematch.toFixed(2)} €`;
    }


    // Event Listener for Update on interaction with checkboxes

    checkboxes.forEach(checkbox => {

        checkbox.addEventListener('input', FillSpan);
        
    })
    
// mobile nav

    const navButton = document.querySelector("header.header .mobilemenu");
    const nav = document.querySelector("header.header");
    
    navButton.addEventListener("click", function(e) {
        nav.classList.toggle("active");
    });


// TARGET BLANK FOR EXTERNAL LINKS IN RICHTEXT FIELDS

    let links = document.querySelectorAll('a[href^="http://"], a[href^="https://"]');

    links.forEach(link => link.setAttribute('target', '_blank'));
});