/**
 * Logic for instant price calculation below the metal analysis form
 * at /labor/kundenauftrag-metallanalytik/
 */
document.addEventListener("DOMContentLoaded", function () {
  // 1 Product = 17, 2 Products = 26, etc.
  const fixedPrice = [22.0, 32.0, 42.0];
  // Price for any additional product for n products with n > fixedPrice.length
  const additionalPrice = 10.0;
  const checkboxInputName = "untersuchungsprogramm";
  /**
   * Given the number of selected metals, the price calculates as
   *
   * 1 Metal = fixedPrice[0]
   * 2 Metals = fixedPrice[1]
   * ...
   * Any number, that exceeds the length of fixedPrice contributes
   * to the price with the fixed amount of `additionalPrice`
   *
   */
  function calcPrice(num) {
    if (num === 0) {
      return 0;
    } else if (num <= fixedPrice.length) {
      return fixedPrice[num - 1];
    } else {
      return (
        fixedPrice[fixedPrice.length - 1] +
        (num - fixedPrice.length) * additionalPrice
      );
    }
  }

  /**
   *
   * 3.146 -> "3,15"
   *
   * @param {Number} num
   * @returns String with two digits after the decimal sep
   *    and using ',' as separator.
   */
  function formatPrice(num) {
    const rounded = Number.parseFloat(num).toFixed(2);
    return rounded.toString().replace(".", ",");
  }

  /** Get number of checked checkboxes. */
  function numCheckedCheckboxes() {
    return document.querySelectorAll(
      `input[name='${checkboxInputName}'][type=checkbox]:checked`
    ).length;
  }

  /** Get all checked checkboxes (as querySelector) */
  function getAllCheckboxes() {
    return document.querySelectorAll(
      `input[name='${checkboxInputName}'][type=checkbox]`
    );
  }

  // Paste prices into the element with id 'price'
  const priceElement = document.getElementById("price");
  if (priceElement) {
    // init
    priceElement.textContent = formatPrice(
      calcPrice(numCheckedCheckboxes()).toString()
    );
  }

  // Whenever a checkbox state changes, recalculate the price
  getAllCheckboxes().forEach((checkbox) => {
    checkbox.addEventListener("change", () => {
      priceElement.textContent = formatPrice(
        calcPrice(numCheckedCheckboxes()).toString()
      );
    });
  });
});
